import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { StepViewHidden } from '../StepViewHidden';

import {
  ListParticipantStepsResponse,
  ParticipantSection,
  ParticipantStep,
} from '@wix/ambassador-challenges-v1-participant/types';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { Spinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import utils, { getFlatStepsList } from '../../views/utils';

import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import { useSingleEndInteraction } from '../../../../../contexts/Monitoring/MonitoringContext';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';

import { Ricos } from '../../../../../components-shared/Ricos/loadable/Ricos';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { classes, st } from './SideBarStepView.st.css';

export interface ISideBarStepViewProps {
  dataHook?: string;
  challenge: Challenge;
  isListParticipantSectionsRequestInProgress: boolean;
  isParticipantStepsLoading: boolean;
  currentStep: ParticipantStep;
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
  goToCurrentStep(e: any): void;
  Breadcrumbs: () => React.ReactElement;
  StepShareButton: () => React.ReactElement;
  pageRef: React.RefObject<HTMLElement>;
}

export const SideBarStepView: FCWithChildren<ISideBarStepViewProps> = (
  props,
) => {
  const { isMobile } = useEnvironment();
  const {
    challenge,
    isListParticipantSectionsRequestInProgress,
    isParticipantStepsLoading,
    currentStep,
    listParticipantSections,
    participantSteps,
    Breadcrumbs,
    StepShareButton,
    pageRef,
  } = props;
  const stepId = currentStep?.id;
  const { participantSteps: pSteps, requestParticipantStep } = useSections();
  const flatStepsList = getFlatStepsList({
    listParticipantSections,
    participantSteps,
  });
  const originalStep =
    stepId && pSteps?.steps?.find((step) => step.id === stepId); // we need to get an original step here because it will have the entire data
  const isStepHidden = utils.isStepHidden(
    challenge,
    flatStepsList,
    originalStep,
  );
  const description = originalStep?.source?.settings?.general?.description;
  const details = description?.details;
  const isDelayedDetails = details === undefined; // only `undefined` is suitable here

  React.useEffect(() => {
    if (isDelayedDetails && !isStepHidden) {
      requestParticipantStep?.(stepId);
    }
  }, [stepId]);

  useSingleEndInteraction(FedopsInteraction.ViewStepAtSidebarLayout, stepId);

  if (!currentStep) {
    return null;
  }

  return isListParticipantSectionsRequestInProgress ||
    isParticipantStepsLoading ? (
    <Spinner type={SpinnerTypes.slim} isCentered={false} />
  ) : (
    <div
      id={stepId}
      className={st(classes.root, {
        mobile: isMobile,
      })}
      data-hook={props.dataHook || null}
    >
      {!isStepHidden ? (
        <>
          <Breadcrumbs />
          <Fullscreen pageRef={pageRef} />

          <TextWithDirection>
            <h3 className={classes.stepViewTitle}>
              {description?.title}
              {isMobile ? (
                <div className={classes.stepViewTitleShare}>
                  <StepShareButton />
                </div>
              ) : null}
            </h3>
          </TextWithDirection>

          {isDelayedDetails ? (
            <Spinner
              type={SpinnerTypes.slim}
              isCentered={!isMobile}
              className={classes.spinner}
            />
          ) : (
            <Ricos
              key={stepId}
              details={details}
              contentId={stepId}
              context="program_step"
              dataHook="program-step"
            />
          )}
        </>
      ) : (
        <StepViewHidden
          date={originalStep?.dateFrame?.start}
          goToCurrentStep={(e) => props.goToCurrentStep(e)}
        />
      )}
    </div>
  );
};
